import * as React from "react"
import { graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { options } from "../components/options"
import { Title } from "../components/styles"

const Upcoming = ({ data }) => {
  const {
    appendix: { title, text },
  } = data

  return (
    <Layout>
      <Seo title={title} />
      <main>
        <Title>{title}</Title>
        <article>{text && renderRichText(text, options)}</article>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    appendix: contentfulAppendix(
      slug: { eq: $slug }
    ) {
      title
      text {
        raw
      }
    }
  }
`

export default Upcoming
